<template>
  <div>
    <!--    <el-radio-group v-for='item of typeList' @change='serchs(item.id)' disabled='true' v-model='labelPosition' size='small'>-->
    <!--      <el-radio-button style='margin-right: 10px;' :label='item.id'>{{ item.value }}</el-radio-button>-->
    <!--    </el-radio-group>-->
    <div style="margin: 10px;"></div>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column
        type="index"
        prop="sortOrder"
        header-align="center"
        align="center"
        label="序号"
        width="60"
        :index="indexMethod"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        label="名称"
        prop="name"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        label="英文名称"
        prop="enName"
      >
      </el-table-column>
      <el-table-column
        :formatter="formatterIsShow"
        align="center"
        header-align="center"
        label="是否显示"
        prop="isShow"
        width="150"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        label="排序"
        prop="sortOrder"
        width="200"
      >
        <template slot-scope="scope">
          <el-button
            icon="el-icon-bottom"
            size="small"
            @click="handleSort(scope.row.id, 'down')"
          ></el-button>
          <el-button
            icon="el-icon-top"
            size="small"
            @click="handleSort(scope.row.id, 'up')"
          ></el-button>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        label="操作"
        width="240"
      >
        <template slot-scope="scope">
          <el-button
            icon="el-icon-edit"
            size="mini"
            type="primary"
            @click="handleEdit(scope.row.id)"
            >编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 10px; height: 32px; width: 100%"></div>
    <el-dialog
      :append-to-body="true"
      :title="this.title"
      :visible.sync="dialogFormVisible"
      top="5vh"
      width="800px"
      @close="close('formName')"
    >
      <el-form ref="formName" :model="form" :rules="rules">
        <el-form-item
          label="背景图片："
          prop="picPath"
          style="width: 100%;
          display: inline-block;
          margin: 14px -4px;}"
        >
          <el-upload
            ref="upload"
            :before-upload="imgUpload"
            :file-list="imgList"
            :limit="1"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            action=""
            class="formInput"
            clearFiles
            list-type="picture-card"
            style="width: 79.8%;margin-bottom: -15px;margin-top: -24px;"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :append-to-body="true" :visible.sync="dialogVisible">
            <img :src="dialogImageUrl" alt="" class="images" width="100%" />
          </el-dialog>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item class="form" label="导航名称：" prop="name">
              <el-input
                v-model="form.name"
                class="formInput"
                placeholder="请输入导航名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item class="form" label="英文名称：" prop="enName">
              <el-input
                v-model="form.enName"
                class="formInput"
                placeholder="请输入英文名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item class="form" label="是否显示：" prop="name">
              <el-select class="formInput" v-model="form.isShow" placeholder="请选择是否显示">
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item
          style="display: flex;flex-direction: row;justify-content: flex-end;margin: 10px 0;"
        >
          <el-button type="primary" @click="formSave('formName')"
            >确 定
          </el-button>
          <el-button @click="formCancel('formName')">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  foregroundNavigationDetails,
  uploadFile,
  webServerHttpUrl,
  foregroundNavigationUpdate,
  foregroundNavigationSave,
  foregroundNavigationSort,
  foregroundNavigationQueryList
} from '@/api/homeNav'
import { VueEditor } from 'vue2-editor'

export default {
  components: { VueEditor },
  data() {
    return {
      customToolbar: [
        // 富文本框配置文件
        ['bold', 'italic', 'color', 'background', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['image', 'video', 'code-block', 'code', 'link'],
        ['clean']
      ],
      web: null, // 图片路径
      imgList: [], // 图片数据
      dialogImageUrl: '', // 图片显示的路径
      title: null, // 表单弹窗标题
      tableData: [],
      typeList: [],
      labelPosition: 1,
      recordsTotal: null, // 数据总数
      pageInfo: {},
      dialogFormVisible: false, // 表单弹框开关
      dialogVisible: false,
      folder: {
        folder: 'rotatePictures'
      },
      options: [
        {
          id: 'Y',
          name: '显示'
        },
        {
          id: 'N',
          name: '不显示'
        }
      ],
      current: 1,
      size: 10,
      params: {
        name: ''
      },
      form: {
        articleSource: null,
        author: null,
        content: null,
        picPath: null,
        name: null,
        id: null,
        releaseTime: null,
        viewsNumber: null,
        enName: null,
      },
      rules: {
        picPath: [
          { required: true, message: '请输选择轮播图', trigger: 'blur' }
        ],
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        enName: [{ required: true, message: '请输入英文名称', trigger: 'blur' }],
        articleSource: [
          { required: true, message: '请输入文章出处', trigger: 'blur' }
        ],
        author: [{ required: true, message: '请输入作者', trigger: 'blur' }],
        releaseTime: [
          { required: true, message: '请输选择发布时间', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    // this.getList()
  },
  watch: {},
  mounted() {
    this.getList()
  },
  methods: {
    // 文件上传时钩子
    imgUpload(file) {
      this.imgList = []
      let formdata = new FormData()
      formdata.append('folder', 'homeNav')
      formdata.append('file', file)
      uploadFile(formdata).then(res => {
        // this.dialogImageUrl = this.web + res;
        this.imgList.push({
          url: this.web + res
        })
        console.log('this.imgList',this.imgList)
        this.form.picPath = res
      })
    },
    // 文件列表移除文件时的钩子
    handleRemove() {
      this.form.picPathHttpUrl = null
    },
    // 点击文件列表中已上传的文件时的钩子
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogFormVisible = true
      this.dialogVisible = true
    },
    // // 图片上传返回的数据
    // handleAvatarSuccess(response) {
    //   this.form.picPath = response
    // },
    //清空表单
    formData() {
      this.form.name = null
      this.form.articleSource = null
      this.form.author = null
      this.form.content = null
      this.form.releaseTime = null
      this.form.viewsNumber = null
      this.form.id = null
      this.picPath = null
    },
    indexMethod(index) {
      index = index + 1 + (this.current - 1) * this.size
      return index
    },

    getList() {
      webServerHttpUrl().then(res => {
        this.web = res
      })
      foregroundNavigationQueryList().then(res => {
        this.tableData = res
      })
    },
    // 排序
    handleSort(id, upOrDown) {
      foregroundNavigationSort(id, upOrDown).then(() => {
        this.getList()
      })
    },
    formatterIsShow(row) {
      if (row.isShow == 'Y') {
        return '显示'
      }
      return '不显示'
    },
    // 弹框关闭事件
    close(formName) {
      this.$refs[formName].resetFields()
      if (this.$refs.upload != null) {
        this.$refs.upload.clearFiles()
      }
    },
    //保存按钮
    formSave(formName) {
      console.log(this.form)
      let _this = this
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$confirm('您确定保存吗？', '信息提示', {
            cancelButtonClass: 'btn-custom-cancel',
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              console.log(_this.form)
              if (this.form.id == null) {
                foregroundNavigationSave(_this.form).then(res => {
                  console.log(res)
                  this.$alert('保存成功！', '信息提示', {
                    confirmButtonText: '确定',
                    type: 'success'
                  }).then(() => {
                    this.getList()
                    this.formData()
                    this.dialogFormVisible = false
                    this.$refs[formName].resetFields()
                  })
                })
              } else {
                foregroundNavigationUpdate(_this.form).then(res => {
                  console.log(res)
                  this.$alert('保存成功！', '信息提示', {
                    confirmButtonText: '确定',
                    type: 'success'
                  }).then(() => {
                    this.formData()
                    this.getList()
                    this.dialogFormVisible = false
                    this.$refs[formName].resetFields()
                  })
                })
              }
            })
            .catch(() => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    //新增
    // userAddData() {
    //   this.imgList = []
    //   this.title = '新增'
    //   this.formData()
    //   this.dialogFormVisible = true
    // },
    //编辑
    handleEdit(id) {
      this.imgList = []
      this.title = '编辑'
      this.formData()
      foregroundNavigationDetails(id).then(res => {
        this.form = res
        this.form.id = res.id
        this.imgList.push({
          url: res.picPathHttpUrl
        })
        this.dialogFormVisible = true
      })
    },
    // 取消
    formCancel(formName) {
      this.dialogFormVisible = false
      this.$refs[formName].resetFields()
    }
  }
}
</script>

<style lang="less" scoped>
.home {
  margin: auto;
}

.form {
  width: 95%;
  display: inline-block;
  margin: 10px 10px;
}

/deep/ .el-upload-list__item-actions {
  width: 200px;
  height: 100px;
}

.form1 {
  width: 100%;
  display: inline-block;
  margin: 13px 10px;
}

.formInput {
  width: 67%;
  /*margin-left: 16px;*/
}

/deep/ .el-dialog__body {
  margin-top: -19px;
}

/deep/ .ql-editor {
  min-height: 140px !important;
}

/deep/ .el-upload--picture-card {
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: 148px;
  height: 148px;
  line-height: 80px;
  vertical-align: top;
}

/deep/ .el-upload--picture-card {
  width: 70px;
  height: 70px;
  margin-top: 37px;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 300px;
  height: 150px;
}

/deep/ .el-upload-list__item-actions {
  width: 300px;
  height: 150px;
}

/deep/ #quill-container {
  height: 100px;
}

/deep/ [data-v-0dd82d4c] .ql-editor {
  min-height: 100px !important;
}

/*去除upload组件过渡效果*/
/deep/ .el-upload-list__item {
  transition: none !important;
}
</style>
